<template>
  <div class="row mt-1">
    <div class="col-12">
      <div>
        <div class="bg-light-primary rounded-top py-half px-1">
          <div class="psh-header mb-0 d-flex align-items-center px-0">
            <div class="psh-title border-0 mr-0">
              <i class="icon icon-psh-delivery7 text-h1" style="font-size: 2rem;" />
            </div>
            <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
              Reserved Items
            </h4>
          </div>
        </div>
        <div class="border rounded-bottom p-half">
          <div v-if="reservedItems && reservedItems.length === 0">
            <div class="text-warning p-half m-1 rounded">
              <p-icon name="bi-exclamation-triangle" class="mr-half" />
              Please use the search button
              <div class="bg-primary d-inline rounded" style="padding: 4px;">
                <p-icon name="feather-search" size="14px" color="white" />
              </div>
              located in the Order Items section above to find and select a product.
            </div>
          </div>
          <div v-for="item in reservedItems" :key="item.id" class="custom-border">
            <div class="d-flex">
              <div class="align-self-center" role="button" @click="enlargeImage(item.largeImage)">
                <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="height: 80px !important; width: 80px !important; margin-right: 0.625rem" class="rounded text-white">
              </div>
              <div class="flex-grow-1">
                <h4 class="d-inline text-warning" style="font-size:16px;">
                  {{ item.productTitle }}
                </h4>
                <div>
                  <div class="d-inline">
                    <small class="text-muted" style="margin-right: 3px">UPC</small>
                    <span>{{ item.productUpc || '-' }}</span>
                    <copy-button v-if="item.productUpc" :copy-value="item.productUpc" />
                  </div>
                  <div class="d-inline ml-1">
                    <small class="text-muted" style="margin-right: 3px">ASIN</small>
                    <span>{{ item.productAsin || '-' }}</span>
                    <copy-button v-if="item.productAsin" :copy-value="item.productAsin" />
                  </div>
                  <div class="d-inline ml-1">
                    <small class="text-muted" style="margin-right: 3px">FNSKU</small>
                    <span>{{ item.productFnsku || '-' }}</span>
                    <copy-button v-if="item.productFnsku" :copy-value="item.productFnsku" />
                  </div>
                </div>
                <div>
                  <div class="d-inline">
                    <small class="text-muted" style="margin-right: 3px">MSKU</small>
                    <span>{{ item.inventoryMsku || '-' }}</span>
                    <copy-button v-if="item.inventoryMsku" :copy-value="item.inventoryMsku" />
                  </div>
                </div>
                <div class="d-inline">
                  <small class="text-muted" style="margin-right: 3px">METRICS</small>
                  <fbm-product-metrics id="target" class="d-inline"
                    :product-id="item.productId"
                    :weight="item.productWeight" :length="item.productDimLength"
                    :width="item.productDimWidth" :height="item.productDimHeight"
                  />
                </div>
                <span v-if="item.additionalItems" class="badge badge-light-primary">Additional</span>
              </div>
              <div>
                <div class="d-flex text-center">
                  <div style="width:90px">
                    <small class="text-muted">
                      Location
                    </small>
                    <h5 style="font-size:16px">
                      {{ item.locationName || '-' }}
                    </h5>
                  </div>
                  <div>
                    <small class="text-muted">
                      Reserved
                    </small>
                    <h4>
                      {{ item.reservedCount }}
                    </h4>
                  </div>
                </div>
                <div class="px-half text-right">
                  <dx-util-button icon="remove" type="danger" class="mx-half" @click="unreserveItem(item)" />
                  <dx-util-button v-if="!item.additionalItems" icon="icon icon-feather-paperclip" type="default" class="" @click="openSearchInventory(item)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <fbm-inventory-search :component-id="fbmInventorySearchCompId" :search-data="searchData" />
    </div>
    <div class="col-12">
      <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { confirm } from 'devextreme/ui/dialog'
import fbmItemReservation from '@/http/requests/fbm/fbmItemReservation'
import CoolLightBox from 'vue-cool-lightbox'
import useFbmState from '../useFbmStates'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    'fbm-product-metrics': () => import('./FBMProductMetrics.vue'),
    'fbm-inventory-search': () => import('./FbmInventorySearch.vue'),
    'light-box': CoolLightBox,
  },
  setup() {
    const {
      reservedItems, currentOrder, getReservedItems, fbmOrderReloadNeeded, preventShipmentSave,
    } = useFbmState()
    return {
      reservedItems, currentOrder, getReservedItems, fbmOrderReloadNeeded, preventShipmentSave,
    }
  },
  data() {
    return {
      fbmInventorySearchCompId: '',
      images: [],
      index: null,
      searchData: {
        storeId: null,
        fbmOrderItemId: null,
        orderSku: null,
        orderId: null,
        isAdditionalItem: false,
        orderQuantity: 0,
      },
    }
  },
  methods: {
    openSearchInventory(item) {
      this.searchData.storeId = item.storeId
      this.searchData.orderSku = item.orderSku
      this.searchData.fbmOrderItemId = item.fbmOrderItemId
      this.searchData.inventoryItemId = item.inventoryItemId
      this.searchData.productName = item.productTitle
      this.searchData.orderId = this.currentOrder.id
      this.searchData.isAdditionalItem = true
      this.fbmInventorySearchCompId = uuidv4()
    },
    async unreserveItem(item) {
      const confirmResult = confirm(
        `Are you sure you want to delete "${item.productTitle}"? You won't be able to revert this!`,
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          await fbmItemReservation.deleteReservation(item.id)
          await this.getReservedItems(this.currentOrder.id)
          this.fbmOrderReloadNeeded = uuidv4()
          this.preventShipmentSave = this.preventShipmentSave.filter(el => el !== item.productId)
        }
      })
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
  },

}
</script>

<style lang="scss" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}
span:hover {
  color: #66bcf6;
}

.custom-border {
  border-bottom: 0.5px solid rgb(68, 77, 85);
  margin-bottom: 6px;
  padding-bottom: 3px;
}
.custom-border:last-child {
  border-bottom: none;
}
</style>
